// this file is automatically generated by version.sh script
export const version: Version = {
  version: '2.16.8',
  revision: 'b917ce1f1',
  branch: 'HEAD',
  timestamp: '09.10.2024'
};

export interface Version {
  revision: string;
  version: string;
  branch: string;
  timestamp: string;
}
