import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MedsurfViewerCommonModule } from './common/medsurf-viewer-common.module';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { SlideModule } from './slide/slide.module';
import { ElearningsModule } from './elearnings/elearnings.module';
import { LoginModule } from './login/login.module';
import { ErrorsModule } from './errors/errors.module';
import { PagesModule } from './pages/pages.module';
import { SubjectsModule } from './subjects/subjects.module';
import { ChaptersModule } from './chapters/chapters.module';
import { createTranslateLoader } from './translate.loader';
import { FLAT_SETTINGS } from '@medsurf/flat-services';
import * as FlatStore from '@medsurf/flat-states';
import * as Facades from '@medsurf/flat-facades';
import { DefaultViewerErrorHandler } from '@medsurf/flat-error-handlers';
import { MedsurfLibModule, NavigationServiceInterface } from '@medsurf/module';
import { SETTINGS } from '@medsurf/services';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      // Flat Control Store
      FlatStore.AlertControlState,
      FlatStore.AuthControlState,
      FlatStore.EntityControlState,
      FlatStore.ErrorControlState,
      FlatStore.MetadataControlState,
      FlatStore.NavigationControlState,
      FlatStore.NodeControlState,
      FlatStore.PageControlState,
      FlatStore.SearchControlState,
      FlatStore.SlideControlState,
      FlatStore.TreeControlState,
      FlatStore.WsControlState,
      // Flat Entity Store
      FlatStore.AnnotationEntityState,
      FlatStore.AnnotationGroupEntityState,
      FlatStore.AnnotationLabelEntityState,
      FlatStore.AnnotationSourceEntityState,
      FlatStore.AnnotationTargetEntityState,
      FlatStore.ChoiceEntityState,
      FlatStore.CopyrightEntityState,
      FlatStore.FreeFormStyleEntityState,
      FlatStore.ImageEntityState,
      FlatStore.KeymapColumnEntityState,
      FlatStore.KeymapLabelEntityState,
      FlatStore.KeymapStyleEntityState,
      FlatStore.MediaEntityState,
      FlatStore.MetadataEntityState,
      FlatStore.ModalityEntityState,
      FlatStore.NodeEntityState,
      FlatStore.NoteEntityState,
      FlatStore.PageEntityState,
      FlatStore.PointOfInterestEntityState,
      FlatStore.QuestionEntityState,
      FlatStore.SlideLayerEntityState,
      FlatStore.StainEntityState,
      FlatStore.UserEntityState,
      FlatStore.VocabularyGroupEntityState,
      FlatStore.VocabularyWordEntityState,
      // Flat Viewer Store
      FlatStore.FeedbackViewerState,
      FlatStore.MenuViewerState,
      FlatStore.TrainingViewerState,
      FlatStore.SlideViewerState,
      FlatStore.ImageViewerState,
    ], {
      executionStrategy: NoopNgxsExecutionStrategy,
      developmentMode: false, // TODO IMPORTANT true for develop
      selectorOptions: {
        suppressErrors: true, // TODO IMPORTANT false for develop
        injectContainerState: false,
      }
    }),
    NgxsStoragePluginModule.forRoot({
      key: [
        // Flat Control Store
        'authControl.token',
        'authControl.tokenLifetime',
        'authControl.expiresIn',
        'trainingViewer.selectedSlides',
        'trainingViewer.selectedStartUrl',
        'trainingViewer.selectedSlideResults',
        'menuViewer.showVirtualPointer',
        'menuViewer.showNavigator',
        'menuViewer.showMarkers',
        'menuViewer.showSelftest',
        'menuViewer.showCaseHistory',
      ]
    }),
    NgxsRouterPluginModule.forRoot(),
    /* TODO IMPORTANT * /
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    /**/
    MedsurfLibModule,
    MedsurfViewerCommonModule,
    AppRoutingModule,
    SlideModule,
    ElearningsModule,
    LoginModule,
    ErrorsModule,
    PagesModule,
    SubjectsModule,
    ChaptersModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    // Error Handlers
    // {provide: Sentry.TraceService, deps: [Router]},
    // {provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true},
    // Settings
    {provide: SETTINGS, useValue: environment.config},
    {provide: FLAT_SETTINGS, useValue: environment.config},
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    // Control Facades
    {provide: Facades.AlertControlFacade, useClass: Facades.AlertControlFacade},
    {provide: Facades.AuthControlFacade, useClass: Facades.AuthControlFacade},
    {provide: Facades.NavigationControlFacade, useClass: Facades.NavigationControlFacade},
    {provide: Facades.SearchControlFacade, useClass: Facades.SearchControlFacade},
    {provide: Facades.WsControlFacade, useClass: Facades.WsControlFacade},
    // Guard Facades
    {provide: Facades.BaseGuardChainFacade, useClass: Facades.BaseGuardChainFacade},
    // Shared Facades
    {provide: Facades.AppSharedFacade, useClass: Facades.AppSharedFacade},
    {provide: Facades.NodeSharedFacade, useClass: Facades.NodeSharedFacade},
    {provide: Facades.PageSharedFacade, useClass: Facades.PageSharedFacade},
    {provide: Facades.SlideViewerFacade, useClass: Facades.SlideViewerFacade},
    // Viewer Facades
    {provide: Facades.FeedbackViewerFacade, useClass: Facades.FeedbackViewerFacade},
    {provide: Facades.MenuViewerFacade, useClass: Facades.MenuViewerFacade},
    {provide: Facades.TrainingViewerFacade, useClass: Facades.TrainingViewerFacade},
    {provide: Facades.ImageViewerFacade, useClass: Facades.ImageViewerFacade},
    {provide: Facades.VocabularyViewerFacade, useClass: Facades.VocabularyViewerFacade},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
